import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_NavDrawer = _resolveComponent("NavDrawer")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_NotificationDrawer = _resolveComponent("NotificationDrawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_DialogsWrapper = _resolveComponent("DialogsWrapper")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_toast, { ref: "toast" }, null, 512),
      (_ctx.userSignedIn && !_ctx.isLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_NavDrawer),
            _createVNode(_component_NavBar),
            _createVNode(_component_NotificationDrawer)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_router_view, {
            key: _ctx.$route.fullPath
          }))
        ]),
        _: 1
      }),
      _createVNode(_component_DialogsWrapper)
    ]),
    _: 1
  }))
}