<template>
    <v-row :class="isVertical ? 'h-screen' : 'w-100'" align="center" justify="center">
        <v-progress-circular :size="progressSize" :width="progressWidth" color="primary" indeterminate></v-progress-circular>
    </v-row>
</template>

<script lang="ts">
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator'
@Component
class Loading extends Vue {
  @Prop({
    default: true
  }) isVertical?: boolean

  @Prop({
    default: 70
  }) progressSize?: number

  @Prop({
    default: 7
  }) progressWidth?: number
}
export default toNative(Loading)
</script>

<style>

</style>
