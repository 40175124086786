export const setValidationHeaders = (stagingVersion: string) => {
  sessionStorage.setItem('customHeaders', JSON.stringify({
    'aws-cf-cd-validation-version': '1'
  }))
  sessionStorage.setItem('stagingVersion', stagingVersion)

  window.location.href = `${window.location.origin}/${stagingVersion}/staging.html`
}

export const isValidating = () => {
  const stagingVersion = sessionStorage.getItem('stagingVersion')
  if (!stagingVersion && window.location.pathname.includes('/staging')) {
    window.location.href = window.location.origin
  }

  return sessionStorage.getItem('customHeaders')?.includes('aws-cf-cd-validation-version')
}
