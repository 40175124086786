/* eslint-disable import/first */
const stagingVersion = sessionStorage.getItem('stagingVersion')
if (stagingVersion) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
  __webpack_public_path__ = `/${stagingVersion}/`
}

import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios'
import { vMaska } from 'maska'
import VueAxios from 'vue-axios'
import VueNativeSock from 'vue-native-websocket-vue3'
import * as ConfirmDialog from 'vuejs-confirm-dialog'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './stores'
import apolloProvider from './vue-apollo'

import NavBar from '@/components/Navbar.vue'
import NavDrawer from '@/components/NavDrawer.vue'

import AmplifyVue from '@aws-amplify/ui-vue'
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'

import 'mathlive/dist/mathlive-fonts.css'
import 'mathlive/dist/mathlive-static.css'

Amplify.configure(awsconfig)

const app = createApp(App)

app.use(ConfirmDialog)
app.use(AmplifyVue)
app.use(VueNativeSock,
    process.env.VUE_APP_WEBSOCKET_URI as string,
    {
      format: 'json',
      reconnection: true,
      reconnectionAttempts: 10
    })

app.component('NavBar', NavBar)
app.component('NavDrawer', NavDrawer)

app.directive('mask', vMaska)

app.use(VueAxios, axios)
app.use(router)
app.use(vuetify)
app.use(store)
app.use(apolloProvider)

app.mount('#app')
