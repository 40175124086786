import router from '@/router'
import store from '@/stores'
import { RouteLocation } from 'vue-router'
import UserAccount, { UserTenancy } from './base/UserAccount'
import Authorizer, { Module, RoleLevel } from './static/Authorizer'

export const getDefaultTenant = (pstore: typeof store | undefined = undefined): UserTenancy => {
  const tenants = (pstore ?? store).getters.userCustomData.tenants
  let defaultTenant = null
  Object.keys(tenants).forEach(tenantKey => {
    if (tenants[tenantKey].isDefault) {
      defaultTenant = tenants[tenantKey]
    }
  })

  return defaultTenant ?? tenants[Object.keys(tenants)[0]]
}

export default class RouterModel {
  private to: RouteLocation
  private from: RouteLocation
  private readonly next

  private readonly signedOutAccessAllowed: boolean
  private readonly signedInAccessAllowed: boolean
  private readonly requiresTenant: boolean
  private readonly userSignedIn: boolean

  constructor (to: RouteLocation, from: RouteLocation, next: any) {
    this.to = to
    this.from = from
    this.next = next

    this.signedOutAccessAllowed = this.to.matched.some(record => record.meta.allowSignedOutAccess)
    this.signedInAccessAllowed = this.to.matched.some(record => record.meta.allowSignedInAccess)
    this.requiresTenant = this.to.matched.some(record => record.meta.requiresTenant)
    this.userSignedIn = store.state.user !== null && store.getters.userCustomData !== null
  }

  handleRoute () {
    if (store.state.userLoaded) {
      if (
        window.location.pathname.includes('/staging') &&
        !store.getters.userCustomData?.testAccount
      ) {
        window.location.href = window.location.origin
      } else {
        this.handleUserLoaded()
      }
    } else {
      switch (this.to.path) {
        case '/signin':
        case '/forgotpassword':
        case '/resetpassword':
        case '/newuserpassword':
          this.next()
          break
        case '/loading':
          if (window.location.pathname === '/loading') {
            window.location.replace(window.location.origin)
          } else {
            this.next()
          }
          break
        default:
          this.handleUserNotLoaded()
      }
    }
  }

  private handleUserLoaded () {
    const allAccessAllowed: boolean = this.signedOutAccessAllowed && this.signedInAccessAllowed
    const signedOutAccessOnly: boolean = this.signedOutAccessAllowed && !this.signedInAccessAllowed
    const signedInAccessOnly: boolean = !this.signedOutAccessAllowed && this.signedInAccessAllowed

    if (allAccessAllowed) {
      this.next()
    } else if (signedOutAccessOnly) {
      this.handleSignedOutAccessOnly()
    } else if (signedInAccessOnly) {
      this.handleSignedInAccessOnly()
    }
  }

  private handleUserNotLoaded () {
    store.commit('setRedirectURL', this.to.path)
    router.push({ name: 'Loading' })
  }

  private handleSignedOutAccessOnly () {
    if (this.userSignedIn) {
      router.push({ name: 'Dashboard', params: { tenant: store.state.currentTenant?.schemaName as string } })
    } else {
      this.next()
    }
  }

  private handleSignedInAccessOnly () {
    const userIsAuthorized: boolean = this.checkUserIsAuthorized()

    if (userIsAuthorized) {
      if (this.userSignedIn) {
        this.handleUserSignedInAsRequired()
      } else {
        this.handleUserNotSignedInAsRequired()
      }
    } else {
      router.push({ name: 'Unauthorized' })
    }
  }

  private async handleUserSignedInAsRequired () {
    const appStatus = store.getters.userCustomData.appStatus
    const urlTenant = store.getters.userCustomData.tenants[this.to.params.tenant as string]
    const urlTenantRequiredAndMissing: boolean = (urlTenant === null || urlTenant === undefined) && this.requiresTenant
    const currentTenantChangedOrMissing: boolean = store.state.currentTenant === null || (urlTenant !== null &&
            urlTenant !== undefined && urlTenant.schemaName !== store.state.currentTenant.schemaName)

    if (appStatus !== 1) {
      switch (appStatus) {
        case 2:
          router.push({ name: 'Switchover' })
          break
        case 0:
        default:
          router.push({ name: 'Maintenance' })
          break
      }

      return
    }

    if (urlTenantRequiredAndMissing) {
      const defaultTenant = getDefaultTenant()
      if (defaultTenant === undefined) {
        router.push({ name: 'Account' })
      } else {
        router.push({ name: this.to.name?.toString(), params: { tenant: getDefaultTenant().schemaName } })
      }
    } else if (currentTenantChangedOrMissing) {
      const newTenant = this.requiresTenant ? urlTenant : getDefaultTenant()
      try {
        await store.dispatch('updateTenant', newTenant)
        this.next()
      } catch (e: any) {
        store.commit('addFatalError', e.message)
      }
    } else {
      this.next()
    }
  }

  private handleUserNotSignedInAsRequired () {
    if (this.from.path === '/signin') {
      store.commit('setRedirectURL', '/')
    } else {
      store.commit('setRedirectURL', this.from.path)
    }

    this.next({
      path: '/signin'
    })
  }

  private checkUserIsAuthorized (): boolean {
    const user: UserAccount = store.getters.userCustomData
    const minimumRoleLevel = this.to.meta.minimumRoleLevel as RoleLevel
    const requiredModule = this.to.meta.requiredModule as Module
    let userIsAuthorized = true

    if (this.to.meta.requiresTenant && this.to.params?.tenant?.length > 0) {
      userIsAuthorized = Authorizer.authorize(minimumRoleLevel, requiredModule, user, this.to.params.tenant as string)
    } else if (!this.to.meta.requiresTenant) {
      userIsAuthorized = Authorizer.authorize(minimumRoleLevel, null, user, null)
    }

    return userIsAuthorized
  }
}
